import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";
import type { ReactNode } from "react";
import { forwardRef } from "react";
import { cn } from "../../helpers/styles";
import type { Props as PrimitiveButtonProps } from "../../primitives/Button/Button";
import { Button as PrimitiveButton } from "../../primitives/Button/Button";

export type ButtonProps = PrimitiveButtonProps &
	VariantProps<typeof buttonVariants> & {
		icon?: ReactNode;
	};

/**
 * Button component that provides a consistent look and feel for buttons
 * @todo Remove icon slot (compose it yourself instead..)
 */
export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
	({ className, variant, size, children, icon, hash, href, ...props }, ref) => {
		const reference = hash ? `#${hash}` : href;

		return (
			<PrimitiveButton
				className={cn(
					buttonVariants({
						variant,
						size,
						hasText: Boolean(children),
					}),
					className,
				)}
				href={reference}
				ref={ref}
				type={reference ? undefined : "button"}
				{...props}
			>
				{icon}
				{children}
			</PrimitiveButton>
		);
	},
);

Button.displayName = "Button";

export const buttonVariants = cva(
	[
		"inline-flex items-center justify-center whitespace-nowrap font-semibold transition-colors disabled:pointer-events-none disabled:select-none disabled:bg-gray-50 disabled:text-gray-400 disabled:shadow-none",
	],
	{
		variants: {
			// Variant determines the colors
			variant: {
				primary:
					"focus:ring-accent-level-2 bg-brand text-white shadow-1 hover:bg-brand-600 hover:shadow-2",
				secondary:
					"focus:ring-accent-level-2 bg-gray-50 shadow-1 hover:border-gray-300 hover:bg-gray-100 hover:shadow-2",
				secondaryColor:
					"focus:ring-accent-level-2 bg-brand-50 text-brand shadow-1 hover:border-gray-300 hover:bg-brand-100 hover:text-brand-600 hover:shadow-2 focus:text-brand",
				tertiary:
					"focus:ring-accent-level-2 text-gray-600 hover:bg-gray-50 disabled:bg-transparent",
				tertiaryColor:
					"focus:ring-accent-level-2 text-brand hover:bg-brand-50 hover:text-brand-600 focus:text-brand disabled:bg-transparent",
				destructive:
					"focus:ring-accent-level-2 bg-error-600 text-white shadow-1 hover:bg-error-700 hover:shadow-2",
				link: "text-gray-600 underline underline-offset-2 hover:text-gray-700 hover:no-underline focus:bg-orange-dark-200 focus:underline disabled:bg-transparent disabled:no-underline",
				linkColor:
					"text-brand underline underline-offset-2 hover:text-brand-600 hover:no-underline focus:bg-orange-dark-200 focus:underline disabled:bg-transparent disabled:no-underline",
			},
			size: {
				lg: "gap-sm",
				xl: "gap-sm",
				xxl: "gap-lg text-lg",
			},
			// without text, it becomes a circular icon button
			hasText: {
				false: "aspect-square rounded-full",
			},
		},
		compoundVariants: [
			{
				variant: [
					"primary",
					"secondary",
					"secondaryColor",
					"tertiary",
					"tertiaryColor",
					"destructive",
				],
				size: "lg",
				className: "px-xl py-2.5",
			},
			{
				variant: [
					"primary",
					"secondary",
					"secondaryColor",
					"tertiary",
					"tertiaryColor",
					"destructive",
				],
				size: "xl",
				className: "px-[18px] py-lg",
			},
			{
				variant: [
					"primary",
					"secondary",
					"secondaryColor",
					"tertiary",
					"tertiaryColor",
					"destructive",
				],
				size: "xxl",
				className: "px-[22px] py-xl",
			},
			{
				variant: ["link", "linkColor"],
				size: "lg",
				className: "text-sm",
			},
		],
		defaultVariants: {
			variant: "primary",
			size: "lg",
		},
	},
);
