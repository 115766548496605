import type { Ref } from "react";
import { forwardRef } from "react";
import { Link, type LinkProps } from "../Link";

export type Props = {
	newTab?: boolean;
	locale?: LinkProps["locale"];
	href?: LinkProps["href"] | string;
	hash?: string;
	scroll?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement> &
	Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, "href">;

/**
 * Functional button component that used to return either a button or a link when href
 * is provided.
 * @returns
 */
export const Button = forwardRef<HTMLButtonElement | HTMLAnchorElement, Props>(function Button(
	{ children, href, scroll, disabled, locale, ...rest },
	ref,
) {
	if (href && !disabled) {
		return (
			<Link
				ref={ref as Ref<HTMLAnchorElement>}
				locale={locale}
				href={href}
				scroll={scroll}
				{...rest}
			>
				{children}
			</Link>
		);
	}

	return (
		<button ref={ref as Ref<HTMLButtonElement>} disabled={disabled} {...rest}>
			{children}
		</button>
	);
});
